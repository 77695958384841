/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppSearch from '@components/AppSearch';
import { post } from '@api';
import { Row, Space, Button, message, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import ChannelOption from './ChannelOption';

const { confirm } = Modal;

const sendReview = async (id) => {
  try {
    message.loading('Sending...', 3);
    await post('/review/send', { id });
    message.success('Done! You will see the reviews in your channel in a moment');
  } catch (error) {
    console.error(error);
  } finally {
    console.log('Success');
  }
};

const showConfirm = (id) => {
  confirm({
    title: 'Do you want to send app reviews to your channel(s) now?',
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      sendReview(id);
    }
  });
};

const AppForm = ({ app: initApp, onCancel, onSubmit, channels }) => {
  const [app, setApp] = useState(initApp || {});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setApp(initApp || {});
  }, [initApp]);

  const onSave = async () => {
    setLoading(true);
    try {
      const { id } = await post('/app/save', app);
      await onSubmit();
      setApp({});
      showConfirm(id);
    } catch (error) {
      message.error('Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!initApp && (
        <>
          <Row>
            <AppSearch
              app={app}
              setApp={setApp}
              size="medium"
              placeholder="Type your app name or paste its url here"
            />
          </Row>
          <br />
        </>
      )}
      {!!app.appId
        && (
          <>
            {(app.channelOptions || [{}]).map((option, index) => (
              <ChannelOption
                id={app.id}
                key={index}
                disableCountry={app.store.indexOf('google_play_store') >= 0}
                channels={channels}
                option={option}
                setOption={(newOption) => {
                  if (app.channelOptions) {
                    const channelOptions = [...app.channelOptions];
                    channelOptions[index] = newOption;
                    setApp({ ...app, channelOptions });
                  } else {
                    setApp({ ...app, channelOptions: [option] });
                  }
                }}
                onDelete={() => setApp({
                  ...app,
                  channelOptions: app.channelOptions.filter((dumb, idx) => index !== idx)
                })}
                deletable={app.channelOptions && app.channelOptions.length > 1}
              />
            ))}

            <Row>
              <Button
                className="full-width"
                type="dashed"
                onClick={() => {
                  if (app.channelOptions) {
                    setApp({ ...app, channelOptions: [...app.channelOptions, {}] });
                  } else {
                    setApp({ ...app, channelOptions: [{}, {}] });
                  }
                }}
              >
                <PlusOutlined />Send reviews to another channel
              </Button>
            </Row>
            <br />
          </>
        )}

      <Row style={{ justifyContent: "flex-end" }}>
        <Space>
          <Button htmlType="button" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSave} loading={loading}>
            Save
          </Button>
        </Space>
      </Row>
    </>
  );
};

AppForm.propTypes = {
  app: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  channels: PropTypes.arrayOf(PropTypes.object)
};

AppForm.defaultProps = {
  app: undefined,
  channels: []
};

export default AppForm;
