import React from 'react';
import Auth from '@aws-amplify/auth';
import { Register as RegisterComponent } from '@components/Auth';

const onRegister = async ({ email, password }) => {
  try {
    const signupInfo = {
      username: email.trim().toLowerCase(),
      password: password.trim(),
      attributes: {
        email: email.trim().toLowerCase(),
      }
    };
    await Auth.signUp(signupInfo);
    return {
      success: true,
      message: "Almost there ... Please check your email to confirm your registration"
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const RegisterContainer = () => (
  <RegisterComponent onRegister={onRegister} />
);

export default RegisterContainer;
