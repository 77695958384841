import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import Layout from '@components/Home/Layout';
import { LoginForm, RegisterForm } from './Form';

const { TabPane } = Tabs;

const Auth = ({ onRegister, onLogin, onResendConfirmEmail, onMfaVerify }) => (
  <Layout className="auth-container">
    <div className="vertical-center">
      <Tabs defaultActiveKey={window.location.pathname === '/register' ? '2' : '1'} className="auth-tabs" animated={false}>
        <TabPane tab="Sign In" key="1">
          <LoginForm onMfaVerify={onMfaVerify} onLogin={onLogin} onResendConfirmEmail={onResendConfirmEmail} />
        </TabPane>
        <TabPane tab="Register" key="2">
          <RegisterForm onRegister={onRegister} />
        </TabPane>
      </Tabs>
    </div>
  </Layout>
);

Auth.propTypes = {
  onRegister: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onMfaVerify: PropTypes.func.isRequired,
  onResendConfirmEmail: PropTypes.func.isRequired
};

export default Auth;
