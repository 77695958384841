import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import Layout from '@components/Home/Layout';

const Success = () => (
  <Layout>
    <Result
      style={{ margin: "auto" }}
      status="success"
      title="Thank you for your subscription"
      subTitle="You can now simply go back to our home page to add more apps or register for more options"
      extra={[
        <Link to="/">
          <Button key="console">
            Home
          </Button>
        </Link>,
        <Link to="/register" key="signup">
          <Button type="primary" key="console">
            Register
          </Button>
        </Link>,
      ]}
    />
  </Layout>
);

export default Success;
