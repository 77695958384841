import React from 'react';
import { ForgotPassword } from '@components/Auth';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';

const onSubmitResetPassword = (email) => {
  Auth.forgotPassword(email);
};

const ForgotPasswordContainer = ({ alert }) => (
  <ForgotPassword onSubmitResetPassword={onSubmitResetPassword} alert={alert} />
);

ForgotPasswordContainer.propTypes = {
  alert: PropTypes.object
};

ForgotPasswordContainer.defaultProps = {
  alert: undefined
};

export default ForgotPasswordContainer;
