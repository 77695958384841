import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const ForbiddenPage = () => (
  <Result
    status="403"
    title="403"
    subTitle="You do not have permission to view this page"
    extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
  />
);

export default ForbiddenPage;
