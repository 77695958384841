import React, { useState } from 'react';
import { Form, Input, Button, Alert, Card } from 'antd';
import PropTypes from 'prop-types';
import { LockOutlined } from '@ant-design/icons';
import Layout from '@components/Home/Layout';
import history from '@app/history';

const ChangePassword = ({ onSubmitResetPassword, onLogin }) => {
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const verifyCode = params.get('code');

  if (!email || !verifyCode) {
    history.push(`/sign-in`);
  }

  const onFinish = async (values) => {
    setLoading(true);
    const { password } = values;
    const { success, message } = await onSubmitResetPassword({ email, verifyCode, password });
    setAlert({ message, type: success ? "success" : "error" });
    if (success) {
      await onLogin({ email, password });
    }
    setLoading(false);
  };

  return (
    <Layout className="auth-container">
      <Card className="vertical-center" bordered={false}>
        <h1>Change Password</h1>
        <Form
          className="login-form"
          onFinish={onFinish}
        >

          <Form.Item>
            <Alert
              message={alert.message || "Please enter your new password below"}
              type={alert.type || "info"}
              showIcon
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please enter your new password' },
              { min: 8, message: 'Your new password is too short' }
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Your new password" />
          </Form.Item>

          <Form.Item span={8}>
            <Button className="full-width" type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
};

ChangePassword.propTypes = {
  onSubmitResetPassword: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired
};

export default ChangePassword;
