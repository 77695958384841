const initGA = () => {
  const script = document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-KD96F63X16Z";
  script.async = true;
  document.body.prepend(script);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-KD96F63X16');
  `;
  document.body.prepend(script2);
};

export default initGA;
