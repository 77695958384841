import { render } from 'react-dom';
import React from 'react';
import { AppContainer } from 'react-hot-loader';
import App from './app/App';

const root = document.getElementById('root');
const load = () => render(
  (
    <AppContainer>
      <App />
    </AppContainer>
  ), root,
);

// This is needed for Hot Module Replacement
if (module.hot) {
  module.hot.accept('./app/App', load);
}

load();
