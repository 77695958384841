import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import AppIcon from './AppIcon';

const { Title, Text } = Typography;
const Size = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};

const App = ({ publisher, icon, name, store, size }) => {
  const iconSize = {
    small: 20,
    medium: 40,
    large: 60
  };
  const nameSize = {
    small: 6,
    medium: 5,
    large: 4
  };
  return (
    <div style={{ display: "flex" }}>
      <AppIcon
        src={icon}
        size={iconSize[size]}
        platform={store === 'google_play_store' ? 'android' : 'ios'}
      />
      <Row style={{ marginLeft: 8 }}>
        <Col span={24}>
          <Title level={nameSize[size]} style={{ marginBottom: 0 }}>
            <span>{name}</span>
          </Title>
        </Col>
        <Col span={24}>
          <Text type="secondary">
            <span>{publisher}</span>
          </Text>
        </Col>
      </Row>
    </div>
);
  };

App.propTypes = {
  publisher: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(Size))
};

App.defaultProps = {
  size: Size.large
};

export default App;
