/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import React from 'react';
import ForbiddenPage from '@components/403';
import NotFoundPage from '@components/404';
import Home from '@components/Home';
import Success from '@components/Success';
import OauthSuccess from '@components/Oauth';
import Dashboard from '@components/Dashboard';
import { PrivacyPolicy } from '@components/Static';
import { SignIn, Register, ForgotPassword, ChangePassword } from '@components/Auth/containers';

export const ProtectedRoute = ({ render: Component, user, authGroups, ...rest }) => {
  const groups = (user ? user.signInUserSession.accessToken.payload['cognito:groups'] : []) || [];

  return (
    <Route
      {...rest}
      render={(rProps) => (
        user && (!authGroups || !authGroups.length || authGroups.find((g) => groups.includes(g)))
          ? <Component {...rProps} />
          : <Redirect to={`/sign-in?redirect=${rProps.location.pathname}${rProps.location.search}`} />
      )}
    />
  );
};

const ProppedRoute = ({ render: Component, ...rest }) => (
  <Route {...rest} render={(rProps) => <Component {...rProps} />} />
);

export const Routes = ({ user }) => (
  <Switch>
    <ProppedRoute exact path="/sign-in" render={SignIn} />
    <ProppedRoute exact path="/register" render={Register} />
    <ProppedRoute exact path="/forgot-password" render={ForgotPassword} />
    <ProppedRoute exact path="/change-password" render={ChangePassword} />
    <ProtectedRoute exact path="/dashboard" render={Dashboard} user={user} />

    <ProppedRoute exact path="/" render={Home} />
    <ProppedRoute exact path="/success" render={Success} />
    <ProppedRoute exact path="/oauth/success" render={OauthSuccess} />

    <Route
      exact
      path="/terms-and-conditions"
      render={
        () => { window.location = "https://reviewscollectbot-public.s3.ap-southeast-1.amazonaws.com/reviewscollectbot.com-t%26c.pdf"; }
      }
    />
    <ProppedRoute exact path="/privacy-policy" render={PrivacyPolicy} />

    <ProppedRoute path="/403" render={ForbiddenPage} />
    <ProppedRoute path="/" render={NotFoundPage} />
  </Switch>
);
export default Routes;
