import Amplify from 'aws-amplify';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

const customHeader = async () => {
  try {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return { Token: `Bearer ${token}` };
  } catch (error) {
    return {};
  }
};

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "MainApi",
        endpoint: process.env.API,
        custom_header: customHeader
      }
    ]
  }
});

const get = async (path, params = {}, host = "MainApi") => {
  const response = await API.get(host, path, { queryStringParameters: params });
  return response;
};

const post = async (path, body = {}, host = "MainApi") => {
  const response = await API.post(host, path, { body });
  return response;
};

export { get, post };
