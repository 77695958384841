/* eslint-disable max-len */
import React, { useState } from 'react';
import { Card, Typography, Input, Form } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const Integration = ({ app }) => {
  const [email, setEmail] = useState();

  const state = JSON.stringify({ ...app, email });
  const slackUrl = `https://slack.com/oauth/v2/authorize?`
    + `client_id=1975394083840.2318717150769&scope=incoming-webhook&user_scope=&`
    + `redirect=https://api.reviewscollectbot.com/public/oauth/slack&`
    + `state=${btoa(state)}`;

  return (
    <Card style={{ width: "100%", marginTop: 16 }}>
      <Form layout="vertical">
        <p><b>Almost done!</b> Just a few steps to go.</p>
        <Form.Item label="1. Enter your email address.">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email address"
            style={{ width: 300, maxWidth: "100%" }}
          />
        </Form.Item>
        <Form.Item label="2. Add &#34;Reviews Collect Bot&#34; to your Slack workspace.">
          <a href={email ? slackUrl : '#'} disabled={!email} style={{ opacity: email ? 1 : 0.5 }}>
            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
          </a>
        </Form.Item>
      </Form>
      <Text>Your new app reviews will start showing up in your Slack channel immediately!</Text>
    </Card>
  );
};

Integration.propTypes = {
  app: PropTypes.object.isRequired
};

export default Integration;
