import React from 'react';
import Auth from '@aws-amplify/auth';
import { ChangePassword } from '@components/Auth';
import useUser from '@hooks/useUser';
import history from '@app/history';
import { post } from '@utils/api';

const onSubmitResetPassword = async ({ email, verifyCode, password }) => {
  try {
    const response = await Auth.forgotPasswordSubmit(email, verifyCode, password);
    return { success: true, message: "Your password has been changed. You will be login in a moment.", response };
  } catch (error) {
    if (['ExpiredCodeException', 'CodeMismatchException'].includes(error.code)) {
      const message = 'Your reset password link has been expired. Please resubmit your request.';
      history.push({ pathname: '/forgot-password', state: { detail: { message, type: "error" } } });
    }
    return { success: false, message: error.message, code: error.code };
  }
};

const ChangePasswordContainer = () => {
  const setUser = useUser()[1];

  const onLogin = async ({ email, password }) => {
    await Auth.signIn(email.trim(), password.trim());
    const user = await Auth.currentAuthenticatedUser();
    const { organisations, inviteUrl, name } = await post(
      '/me',
      { name: user.attributes.name, organisation: user.attributes.website }
    );
    setUser({ ...user, organisations, inviteUrl, name });
    history.push('/');
  };

  return (
    <ChangePassword onSubmitResetPassword={onSubmitResetPassword} onLogin={onLogin} />
  );
};

export default ChangePasswordContainer;
