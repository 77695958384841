const getQueryParam = (name) => {
  const { search } = window.location;
  if (name === 'redirect') {
    return search ? search.replace(`?${name}=`, '') : '/';
  }
  const params = new URLSearchParams(search);
  return params.get(name);
};

export {
  getQueryParam,
};
