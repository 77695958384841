import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@components/Home/Layout';
import { Card } from 'antd';
import { LoginForm } from './Form';

const SignIn = ({ onLogin, onResendConfirmEmail, onMfaVerify }) => (
  <Layout className="auth-container">
    <Card className="vertical-center" bordered={false}>
      <LoginForm onMfaVerify={onMfaVerify} onLogin={onLogin} onResendConfirmEmail={onResendConfirmEmail} />
    </Card>
  </Layout>
);

SignIn.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onMfaVerify: PropTypes.func.isRequired,
  onResendConfirmEmail: PropTypes.func.isRequired
};

export default SignIn;
