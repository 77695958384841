import React, { useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { Result, Button } from 'antd';
import { useUser } from '@hooks';
import { Link, Redirect } from 'react-router-dom';
import Layout from '@components/Home/Layout';

const OauthSuccess = () => {
  const [user, setUser] = useUser();

  const loadUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    setUser(cognitoUser);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    user && user.username
      ? <Redirect to="/dashboard" />
      : (
        <Layout>
          <Result
            style={{ margin: "auto" }}
            status="success"
            title="Done! The reviews will be sent to your selected channel automatically."
            subTitle="You can now simply go back to our home page to add more apps or register for more options"
            extra={[
              <Link to="/" key="home">
                <Button>
                  Home
                </Button>
              </Link>,
              <Link to="/register" key="signup">
                <Button type="primary">
                  Register
                </Button>
              </Link>,
            ]}
          />
        </Layout>
      )
  );
};

export default OauthSuccess;
