import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from '@api';
import { Select } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { App } from '@components/App';
import debounce from 'lodash.debounce';

const { Option } = Select;

const AppSearch = ({ app, setApp, size, placeholder }) => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (value) => {
    if (value) {
      setApp({});
      const response = await get('/public/look-up-app', { query: value });
      setApps(response);
    }
    setLoading(false);
  };

  const debouncedhandleSearch = useMemo(
    () => debounce(handleSearch, 500),
    [app]);

  const onSearch = async (value) => {
    setLoading(true);
    setApps([]);
    debouncedhandleSearch(value);
  };

  const Icon = loading ? LoadingOutlined : SearchOutlined;

  const suffixIconStyle = {
    small: { fontSize: 22, marginTop: -7, marginLeft: -10 },
    medium: { fontSize: 22, marginTop: -7, marginLeft: -10 },
    large: { fontSize: 25, marginTop: -7, marginLeft: -10 }
  };

  return (
    <>
      <Select
        style={{ width: "100%" }}
        size={size}
        loading={loading}
        showSearch
        value={app && app.name}
        placeholder={(!app || !app.name) && placeholder}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={onSearch}
        onChange={(key) => setApp(apps.find(({ store, appId }) => `${store}#${appId}` === key))}
        notFoundContent={null}
        suffixIcon={<Icon style={suffixIconStyle[size]} />}
        autoFocus
      >
        {apps.map(({ store, appId, publisher, icon, name }) => (
          <Option key={`${store}#${appId}`}>
            <App publisher={publisher} icon={icon} name={name} store={store} size={size} />
          </Option>
        ))}
      </Select>
      {loading && (
        <span>
          You can get your app quicker by pasting your app&apos;s url into the search box
        </span>
      )}
    </>
  );
};

AppSearch.propTypes = {
  app: PropTypes.object.isRequired,
  setApp: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
};

AppSearch.defaultProps = {
  size: "large",
  placeholder: "Type your app name to get started"
};

export default AppSearch;
