import React, { useState } from 'react';
import AppSearch from '@components/AppSearch';
import { Grid } from 'antd';
import Integration from '../Integration/Integration';

const { useBreakpoint } = Grid;

const Main = () => {
  const [app, setApp] = useState({});
  const screens = useBreakpoint();

  return (
    <div className="masthead-center">
      <img
        width="200"
        src="/static/trynow_orange.png"
        alt="trynow"
        style={{ position: "absolute", left: screens.lg ? 200 : 20, top: 55 }}
      />
      <AppSearch app={app} setApp={setApp} />
      {app.appId && <Integration app={app} />}
    </div>
  );
};

export default Main;
