import { Form, Switch, Select, Space, Divider } from 'antd';
import { StarFilled, CloseOutlined } from '@ant-design/icons';
import { useUser } from '@hooks';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Country from './country';

const { Option } = Select;

const ChannelOption = ({ id, channels, option, setOption, onDelete, deletable, disableCountry }) => {
  const [user] = useUser();
  const [ratings, setRatings] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [countries, setCountries] = useState([]);
  const [channelMapping, setChannelMapping] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setRatings(option.ratings || [1, 2, 3, 4, 5]);
    setCountries(option.countries || ['all']);
    const mapping = channels.reduce((final, item) => ({
      ...final,
      [item.id]: { channelId: item.id, channelName: item.name, channelType: item.type }
    }), {});
    setChannelMapping(mapping);
    console.log(mapping);
    console.log(option.channelId);
    setSelectedChannel(mapping[option.channelId] || {});
  }, [channels]);

  useEffect(() => {
    setOption({ ...selectedChannel, ratings, countries });
  }, [ratings, countries, selectedChannel]);

  const slackUrl = `https://slack.com/oauth/v2/authorize?`
    + `client_id=1975394083840.2318717150769&scope=incoming-webhook&user_scope=&`
    + `redirect=https://api.reviewscollectbot.com/public/oauth/slack&`
    + `state=${btoa(JSON.stringify({ email: user.username, id }))}`;

  return (
    <Form
      layout="vertical"
      style={{ marginBottom: 16 }}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      {showDelete && deletable && (
        <CloseOutlined
          className="clickable"
          style={{ color: 'red', position: 'absolute', right: 20, zIndex: 3 }}
          onClick={onDelete}
        />
      )}
      <Form.Item label={<b>Slack channel</b>}>
        <Select
          placeholder="Select slack channel to send reviews to"
          onSelect={(channelId) => channelId && setSelectedChannel(channelMapping[channelId])}
          value={selectedChannel && selectedChannel.channelId}
        >
          {channels.map((channel) => (
            <Option key={channel.id}>{channel.name}</Option>
          ))}
          <Option><a href={slackUrl}>Connect to another channel</a></Option>
        </Select>
      </Form.Item>
      <Form.Item label={<b>Filters</b>}>
        <Form.Item label="Country">
          <Select
            value={countries}
            disabled={disableCountry}
            onChange={(v) => setCountries(v)}
            mode="multiple"
            showSearch
            filterOption={(input, opt) => opt.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              || opt.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Object.keys(Country).map((code) => (
              <Option key={code}>{Country[code]}</Option>
            ))}
            <Option value="all" key="all">All Countries</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Rating" style={{ marginBottom: 0 }}>
          <Space>
            {[1, 2, 3, 4, 5].map((star) => (
              <Switch
                key={`star-${star}`}
                checkedChildren={<span>{star} <StarFilled /></span>}
                unCheckedChildren={<span>{star} <StarFilled /></span>}
                onChange={(checked) => (checked ? setRatings([...ratings, star]) : setRatings(ratings.filter((r) => r !== star)))}
                checked={ratings.indexOf(star) >= 0}
              />
            ))}
          </Space>
        </Form.Item>
      </Form.Item>
      <Divider />
    </Form>
  );
};

ChannelOption.propTypes = {
  id: PropTypes.string,
  channels: PropTypes.arrayOf(PropTypes.object),
  option: PropTypes.object.isRequired,
  setOption: PropTypes.func.isRequired,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  disableCountry: PropTypes.bool
};

ChannelOption.defaultProps = {
  id: undefined,
  channels: [],
  deletable: false,
  disableCountry: false,
  onDelete: () => { }
};

export default ChannelOption;
