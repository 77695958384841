/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { getQueryParam } from '@utils';
import { Menu, Layout as AntLayout, Table, Row, message, Tooltip, Button, Grid } from 'antd';
import Layout from '@components/Home/Layout';
import { ButtonModal } from '@components/Common';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useUser } from '@hooks';
import { get, post } from '@api';
import history from '@app/history';
import { App } from '@components/App';
import AppForm from './AppForm';

const { Content, Sider } = AntLayout;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const [, setUser] = useUser();
  const [refresh, setRefresh] = useState();
  const [apps, setApps] = useState([]);
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    const channelName = getQueryParam('channel');
    if (channelName && channelName !== '/') {
      message.success(`Added new slack channel: ${channelName}`, 10);
    }
  }, []);

  const onRemoveApp = async (id) => {
    setLoading(true);
    try {
      await post('/app/remove', { id });
      setRefresh({});
    } catch (error) {
      message.error('Error');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'App',
      render: ({ publisher, icon, name, store }) => (
        <App publisher={publisher} icon={icon} name={name} store={store} />
      )
    },
    {
      title: 'Slack Channel',
      render: (record) => (
        <ul style={{ listStyleType: "none" }}>
          {(record.channelOptions || []).map(({ channelName }) => (
            <li>{channelName}</li>
          ))}
        </ul>
      )
    },
    {
      title: 'Actions',
      render: (record) => (
        <>
          <Tooltip title="Edit">
            <ButtonModal
              type="link"
              icon={(<EditOutlined />)}
              title={`Edit ${record.name}`}
              content={AppForm}
              onSubmit={() => setRefresh({})}
              contentprops={{
                app: record,
                channels
              }}
            />
          </Tooltip>
          <Tooltip title="Remove">
            <Button
              style={{ color: 'red' }}
              onClick={() => onRemoveApp(record.id)}
              type="text"
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </>
      )
    }
  ];

  const signOut = async () => {
    await Auth.signOut();
    setUser(undefined);
    history.push('/sign-in');
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const [{ items: appList }, { items: channelList }] = await Promise.all([
        get('/app/list'),
        get('/channel/list', { type: 'Slack' }),
      ]);
      setApps(appList);
      setChannels(channelList);
    } catch (error) {
      message.error('Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [refresh]);

  return (
    <Layout>
      <AntLayout style={{ maxWidth: 1200, width: "100%", paddingTop: "10vh", margin: "auto", background: "white" }}>
        {screens.lg && (
          <Sider width={180} theme="light">
            <Menu
              defaultSelectedKeys={['dashboard']}
              mode="inline"
              theme="light"
            >
              <Menu.Item key="dashboard">
                <Link to="/dashboard">Apps</Link>
              </Menu.Item>
              <Menu.Item key="signout" onClick={signOut}>
                Sign Out
              </Menu.Item>
            </Menu>
          </Sider>
        )}
        <Content style={{ paddingLeft: 24, paddingTop: 0 }}>
          <Row>
            <ButtonModal
              type="primary"
              icon={<PlusOutlined />}
              label="New App"
              content={AppForm}
              onSubmit={() => setRefresh({})}
              contentprops={{
                channels
              }}
            />
          </Row>
          <br />
          <Table
            loading={loading}
            dataSource={apps}
            columns={columns}
            rowKey="id"
          />
        </Content>
      </AntLayout>
    </Layout>
  );
};

export default Dashboard;
