import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Row, Card } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import Layout from '@components/Home/Layout';

const ForgotPassword = ({ onSubmitResetPassword, alert }) => {
  const [submited, setSubmited] = useState(false);

  const onFinish = async (values) => {
    setSubmited(true);
    const { email } = values;
    onSubmitResetPassword(email);
  };

  const validateEmail = async (rule, value) => {
    if (!value || (value && !value.trim())) {
      throw new Error('Please enter your email address');
    } else if (value && !/^[a-z][a-z0-9_.]{2,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/.test(value.toLowerCase())) {
      throw new Error('The input is not a valid email');
    }
  };

  return (
    <Layout className="auth-container">
      <Card className="vertical-center" bordered={false}>
        <h1>Reset Password</h1>
        <Form
          className="login-form"
          onFinish={onFinish}
        >
          {submited && (
            <Form.Item>
              <Alert
                message="If your email exists in our system, the link to reset your password has been sent to it."
                type="success"
                showIcon
              />
            </Form.Item>
          )}
          {(alert && !submited)
            && (
              <Form.Item>
                <Alert
                  message={alert.message}
                  type={alert.type}
                  showIcon
                />
              </Form.Item>
            )}
          <Row justify="end">
            <Link to="/sign-in">
              Back to Sign In
            </Link>
          </Row>
          {!submited
            && (
              <>
                <Form.Item
                  name="email"
                  rules={[{ validator: validateEmail }]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Your email address" />
                </Form.Item>

                <Form.Item span={8}>
                  <Button className="full-width" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </>
            )}
        </Form>
      </Card>
    </Layout>
  );
};

ForgotPassword.propTypes = {
  onSubmitResetPassword: PropTypes.func.isRequired,
  alert: PropTypes.object
};

ForgotPassword.defaultProps = {
  alert: undefined
};

export default ForgotPassword;
