import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const ButtonModal = (props) => {
  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);
  const buttonProps = {};
  Object.keys(props).forEach((key) => {
    if (['modalWidth', 'content', 'label', 'onSubmit'].indexOf(key) < 0) {
      buttonProps[key] = props[key];
    }
  });

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => setVisible(true)}
      >
        {props.label}
      </Button>
      <Modal
        width={props.modalWidth}
        style={{ top: 20 }}
        footer={null}
        title={props.title || props.label}
        visible={visible}
        onCancel={onCancel}
        onClose={onCancel}
        maskClosable={false}
        {...props.modalprops}
      >
        <props.content
          visible={visible}
          onCancel={() => setVisible(false)}
          onSubmit={async (params) => { await props.onSubmit(params); setVisible(false); }}
          {...props.contentprops}
        />
      </Modal>
    </>
  );
};

ButtonModal.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSubmit: PropTypes.func,
  contentprops: PropTypes.object,
  modalprops: PropTypes.object,
  modalWidth: PropTypes.number
};

ButtonModal.defaultProps = {
  label: undefined,
  title: undefined,
  onSubmit: () => { },
  contentprops: {},
  modalprops: {},
  modalWidth: 520
};

export default ButtonModal;
