/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import Amplify from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { initFonts, initHotjar, initGA } from '@services';
// eslint-disable-next-line no-unused-vars
import styles from '@less/index.less';
import { Routes } from './Route';
import AppContext from './Context';
import history from './history';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const App = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    initFonts();
    if (process.env.ENV === 'prod') {
      initHotjar();
      initGA();
    }
  }, []);

  return (
    <AppContext.Provider value={{ user, setUser }}>
      <Routes user={user} />
    </AppContext.Provider>
  );
};

const AppWithRouter = () => (
  <Router history={history}>
    <App />
  </Router>
);

export default AppWithRouter;
