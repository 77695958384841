/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Typography, Button } from 'antd';
import { Main } from '@components/Main';
import { Link } from 'react-router-dom';

const { ENV } = process.env;
const api = ENV === 'staging' ? 'http://localhost:4000' : 'https://api.reviewscollectbot.com';
const pricing = ENV === 'staging'
  ? {
    plan1: 'price_1JHIWFJKLaDvPCslQ5Y4Ag8C',
    plan2: 'price_1JHIWFJKLaDvPCslWqI9l8Dt',
    plan3: 'price_1JHIWFJKLaDvPCslGsJCka1Z',
    plan4: 'price_1JHIWFJKLaDvPCslFdcIPNrE'
  }
  : {
    plan1: 'price_1JHIRTJKLaDvPCslrUDokIsp',
    plan2: 'price_1JHIRTJKLaDvPCsl5iWJSbm1',
    plan3: 'price_1JHIRTJKLaDvPCslFftWehvH',
    plan4: 'price_1JHIRTJKLaDvPCslTzoarGJF'
  };

const { Title } = Typography;

// export const Nav30DataSource = {
//   wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
//   page: { className: 'home-page' },
//   logo: {
//     className: 'header3-logo jzjgnya1gmn-editor_css',
//     children:
//       'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-J8NSLj9rbsAAAAAAAAAAABkARQnAQ',
//   },
//   Menu: {
//     className: 'header3-menu',
//     children: [
//       {
//         name: 'item1',
//         className: 'header3-item',
//         children: {
//           href: '#',
//           children: [{ children: <p>订订群</p>, name: 'text' }],
//         },
//         subItem: [
//           {
//             className: 'item-sub',
//             children: {
//               className: 'item-sub-item jzj8295azrs-editor_css',
//               children: [
//                 {
//                   name: 'image0',
//                   className: 'item-image jzj81c9wabh-editor_css',
//                   children:
//                     'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*4_S6ToPfj-QAAAAAAAAAAABkARQnAQ',
//                 },
//               ],
//             },
//             name: 'sub~jzj8hceysgj',
//           },
//         ],
//       },
//       {
//         name: 'item2',
//         className: 'header3-item',
//         children: {
//           href: '#',
//           children: [{ children: <p>帮助中心</p>, name: 'text' }],
//         },
//       },
//     ],
//   },
//   mobileMenu: { className: 'header3-mobile-menu' },
// };
export const Nav31DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      '/static/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: window.location.pathname !== '/' ? '/#main' : '#main',
          children: [
            {
              children: (
                <span>
                  <Button type="primary"><b>Try it free</b></Button>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/#pricing',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>Pricing</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      // {
      //   name: 'item2',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: (
      //           <span>
      //             <p>Blog</p>
      //           </span>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      // },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: 'mailto:andrew@extralabs.com.au',
          children: [
            {
              children: (
                <span>
                  <p>Contact Us</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header3-item',
        children: {
          href: '/dashboard',
          children: [
            {
              children: (
                <span>
                  <p>Dashboard</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    id: 'main',
    children: [
      {
        name: 'image',
        children: (
          <>
            <div>
              <img width="30" src="/static/playstore.svg" alt="play store" style={{ marginRight: 16 }} />
              <img width="30" src="/static/appstore.svg" alt="app store" />
            </div>
            <br />
          </>
        ),
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <Title level={1}>Send App Reviews to Slack</Title>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <Main />
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img showcase-img', md: 10, xs: 24 },
  img: {
    style: { width: "100%", maxWidth: 500 },
    children: '/static/showcase.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>
            Stream all app reviews to your Slack channel
          </p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p style={{ fontSize: 18 }}>Translate to your language. Reply with one click.</p>
      </span>
    ),
  },
};

export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img filter-img', md: 12, xs: 24 },
  img: {
    style: { width: "100%", maxWidth: 500 },
    children: '/static/filter.png',
  },
  textWrapper: { className: 'content2-text', md: 12, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>
            Send &quot;good&quot; and &quot;alarming&quot; reviews to different channels
          </p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p style={{ fontSize: 18 }}>Early warning on potential issues.</p>
      </span>
    ),
  },
};

export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Our services</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p style={{ fontSize: 18 }}>We&apos;ll help you take care of all your customers</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/globe.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Global translation</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Understand reviews from all users, no matter where they come from.
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Unlimited Apps</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Get reviews from as many apps on the iOS App Store &amp;
                    Google Play Store as you&apos;d like.
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Reply</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Not to waste a second, just one click to reply to your beloved users.</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/map.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>All Countries</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Receive reviews from all countries supported by the App
                  Store &amp; Play Store.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/uptodate.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Up-to-date</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Get new reviews as soon as they&apos;re available.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Retention</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Increase retention by build features that your community is asking for and resolve their concerns
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    id: "pricing",
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Pricing</p>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p style={{ fontSize: 18 }}>Absolute free for 14 days. Pay monthly. Cancel any time.</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        lg: 6,
        md: 12,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>1 App</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <p>
                    $5
                    <span>&nbsp;</span>
                    <span>/ month</span>
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      App Store or Play Store
                      <div>
                        <p>
                          <span>All Countries</span>
                        </p>
                        <p>
                          <span>Reply &amp; Translate</span>
                        </p>
                      </div>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: `${api}/public/payment/checkout?plan=${pricing.plan1}`,
                children: (
                  <span>
                    <p>Choose Plan</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        lg: 6,
        md: 12,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>3 Apps</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <span>
                    <p>$11 / month</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <p>App Store or Play Store</p>
                <p>All Countries</p>
                <p>Reply &amp; Translate</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: `${api}/public/payment/checkout?plan=${pricing.plan2}`,
                children: (
                  <span>
                    <span>
                      <span>
                        <p>Choose Plan</p>
                      </span>
                    </span>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block3',
        className: 'pricing1-block',
        lg: 6,
        md: 12,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>10 Apps</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <span>
                    <p>$19 / month</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <p>App Store or Play Store</p>
                <p>All Countries</p>
                <p>Reply &amp; Translate</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: `${api}/public/payment/checkout?plan=${pricing.plan3}`,
                children: (
                  <span>
                    <span>
                      <span>
                        <p>Choose Plan</p>
                      </span>
                    </span>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block4',
        className: 'pricing1-block',
        lg: 6,
        md: 12,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>Unlimited Apps</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <p>$39 / month</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <p>
                    <span>App Store or Play Store</span>
                    <br />
                  </p>
                  <p>All Countries</p>
                  <p>
                    Reply &amp; Translate
                    <br />
                    Priority Support
                  </p>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: `${api}/public/payment/checkout?plan=${pricing.plan4}`,
                children: (
                  <span>
                    <p>Choose Plan</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
};
export const Footer01DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          ©2021
          {' '}
          Extra Labs Pty Ltd (ACN 648 945 948)
          {' '}
          All Rights
          Reserved
        </span> | <span>
          <Link to="/terms-and-conditions">
            Terms & Conditions
          </Link>
        </span> | <span>
          <Link to="/privacy-policy">
            Privacy Policy
          </Link>
        </span>
      </>
    ),
  },
};
