/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "reviewscollectbot-prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:f71c946b-5b28-4ecc-b609-cf6478c57f26",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_f57VyFPj3",
    "aws_user_pools_web_client_id": "2cauh5vs1pvra6gmp7e9p9k11p",
    "oauth": {}
};


export default awsmobile;
