import PropTypes from 'prop-types';
import React from 'react';

require('./styles.less');

const AppIcon = ({ src, platform, size, style }) => {
  const platformIconRatio = 1.2;

  return (
    <div
      style={{
        ...style,
        position: "relative",
        textAlign: "center",
        width: size,
        height: size
      }}
    >
      <img
        className="app-icon"
        width={size}
        src={src}
        alt="app-icon"
      />
      {platform && (
      <img
        style={{
          position: 'absolute',
          left: -5 * (size / 50),
          top: -5 * (size / 50),
        }}
        width={size * platformIconRatio}
        height={size * platformIconRatio}
        src={`/static/icon/border/${platform}.png`}
        alt="app-platform"
      />
      )}
    </div>
  );
};

AppIcon.propTypes = {
  src: PropTypes.string.isRequired,
  platform: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object
};

AppIcon.defaultProps = {
  platform: undefined,
  size: 50,
  style: {}
};

export default AppIcon;
