import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@components/Home/Layout';
import { Card } from 'antd';
import { RegisterForm } from './Form';

const Register = ({ onRegister }) => (
  <Layout className="auth-container">
    <Card className="vertical-center" bordered={false}>
      <RegisterForm onRegister={onRegister} />
    </Card>
  </Layout>
);

Register.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default Register;
