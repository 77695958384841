import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Alert, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const LoginForm = ({ onLogin, onResendConfirmEmail, onMfaVerify }) => {
  const [loading, setLoading] = useState(false);
  const [mfa, setMfa] = useState(false);
  const [cognitoUser, setCognitoUser] = useState();

  const params = new URLSearchParams(window.location.search);
  const initialUserName = params.get('username');

  const [alert, setAlert] = useState(
    initialUserName
      ? { message: "Your email has been confirmed. You can now login with your account.", type: 'success' }
      : null
  );

  const resendConfirmEmail = async (email) => {
    const { success, message } = await onResendConfirmEmail(email);
    setAlert({ message, type: success ? "success" : "error" });
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (mfa) {
        const { mfaCode } = values;
        const { success, message } = await onMfaVerify(cognitoUser, mfaCode);
        setAlert({ message, type: success ? "success" : "error" });
      } else {
        const { email, password } = values;
        const { success, code, response, message: msg } = await onLogin({ email, password });
        setCognitoUser(response);
        let message = msg;
        if (code === 'UserNotConfirmedException') {
          message = (
            <span>
              It looks like you haven&apos;t confirmed your email yet. Please
              {' '}
              <a onClick={() => resendConfirmEmail(email)}>click here</a>
              {' '}
              to request a new confirmation email.
            </span>
          );
        }
        if (code === 'MFA') {
          setMfa(true);
          setAlert({ message: "We've sent a authentication code to your phone number", type: "success" });
        } else {
          setAlert({ message, type: success ? "success" : "error" });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = async (rule, value) => {
    if (!value || (value && !value.trim())) {
      throw new Error('Please enter your email address');
    } else if (value && !/^[a-z][a-z0-9_.]{2,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/.test(value.toLowerCase())) {
      throw new Error('The input is not a valid email');
    }
  };

  return (
    <Form
      className="login-form"
      initialValues={{ remember: true, email: initialUserName || params.get('email') || '' }}
      onFinish={onFinish}
    >
      <Row justify="space-between">
        <Title level={5}>Sign in to your account</Title>
      </Row>
      {alert
        && (
          <Form.Item>
            <Alert message={alert.message} type={alert.type} showIcon />
          </Form.Item>
        )}

      {mfa
        ? (
          <>
            <Form.Item name="mfaCode">
              <Input placeholder="Enter authentication code" />
            </Form.Item>
            <Row style={{ justifyContent: "flex-end" }}>
              <a onClick={() => { setMfa(false); setAlert(undefined); }}>
                Cancel
              </a>
            </Row>
            <Form.Item span={8}>
              <Button className="full-width" type="primary" htmlType="submit" loading={loading}>
                Verify
              </Button>
            </Form.Item>
          </>
        )
        : (
          <>
            <Form.Item
              name="email"
              rules={[{ validator: validateEmail }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Your email address" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Your password" />
            </Form.Item>

            <div style={{ textAlign: "right" }}><Link to="/forgot-password">Forgot password</Link></div>

            <Form.Item span={8}>
              <Button className="full-width" type="primary" htmlType="submit" loading={loading}>
                Sign In
              </Button>
            </Form.Item>
            Don&apos;t have an account? <Link to="/register">Register</Link>
          </>
        )}

    </Form>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onMfaVerify: PropTypes.func.isRequired,
  onResendConfirmEmail: PropTypes.func.isRequired,
};

export default LoginForm;
