import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Typography, Row } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const RegisterForm = ({ onRegister }) => {
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const orgName = params.get('org');
  const initialUserName = params.get('email');

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password, name, organisation } = values;
    const { success, message } = await onRegister({ email, password, name, organisation });
    setLoading(false);
    setAlert({ message, type: success ? "success" : "error" });
  };

  const validateEmail = async (rule, value) => {
    if (!value || (value && !value.trim())) {
      throw new Error('Please enter your email address');
    } else if (value && !/^[a-z][a-z0-9_.]{2,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/.test(value.toLowerCase())) {
      throw new Error('The input is not a valid email');
    }
  };

  return (
    <Form
      className="login-form"
      initialValues={{ organisation: orgName, email: initialUserName || '' }}
      onFinish={onFinish}
    >
      <Row justify="space-between">
        <Title level={5}>Create your account</Title>
        <Text>Already have an account? <Link to="/sign-in">Sign In</Link></Text>
      </Row>
      {alert && (
        <Form.Item>
          <Alert message={alert.message} type={alert.type} showIcon />
        </Form.Item>
      )}

      <Form.Item
        name="email"
        rules={[{ validator: validateEmail }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Your email address" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 8, message: 'Your password is too short' }
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Your password" />
      </Form.Item>

      <Form.Item span={8}>
        <Button className="full-width" type="primary" htmlType="submit" loading={loading}>
          Register
        </Button>
      </Form.Item>
      By signing up you agree to the
      <a href="/terms-of-service"> Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>
    </Form>
  );
};

RegisterForm.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default RegisterForm;
