import React, { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { getQueryParam } from '@utils';
import { Redirect } from 'react-router-dom';
import { useUser } from '@hooks';
import { SignIn as SignInComponent } from '@components/Auth';
import { Spin } from 'antd';

const onResendConfirmEmail = async (email) => {
  try {
    await Auth.resendSignUp(email);
    return {
      success: true,
      message: "Confirmation email has been resent to your email"
    };
  } catch (error) {
    console.error(error);
    return { success: false, message: error.message };
  }
};

const SignInContainer = () => {
  const [user, setUser] = useUser();

  const [loading, setLoading] = useState(false);
  const redirect = getQueryParam('redirect');

  const loadUser = async () => {
    setLoading(true);
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setUser(cognitoUser);
    } catch (error) {
      console.error(error);
      setUser(undefined);
      setLoading(false);
    }
  };

  const onLogin = async ({ email, password }) => {
    try {
      const response = await Auth.signIn(email.trim().toLowerCase(), password.trim());
      if (response.challengeName) {
        return { success: true, code: 'MFA', response };
      }
      await loadUser();
      return { success: true, message: "Login succeeded. Redirecting...", response };
    } catch (error) {
      const { message, code } = error;
      console.error(error);
      return { success: false, message, code };
    }
  };

  const onMfaVerify = async (cognitoUser, verificationCode) => {
    try {
      await Auth.confirmSignIn(cognitoUser, verificationCode);
      await loadUser();
      return { success: true, message: "Login succeeded. Redirecting..." };
    } catch (error) {
      console.error(error);
      return { success: false, message: 'Authentication code is incorrect' };
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    user
      ? <Redirect to={redirect && redirect.indexOf('/dashboard') >= 0 ? redirect : `/dashboard`} />
      : (
        <Spin spinning={loading}>
          <SignInComponent
            onLogin={onLogin}
            onResendConfirmEmail={onResendConfirmEmail}
            onMfaVerify={onMfaVerify}
          />
        </Spin>
      )
  );
};

export default SignInContainer;
